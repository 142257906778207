<template>
  <div>
    <!-- 頁面: 購物車列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('cart', 'API.Admin.Cart')">
      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="3"
              sm="6"
              class="mb-md-0 mb-1"
            >
              <label>類別</label>
              <v-select
                v-model="searchType"
                :options="[
                  { label: '網域', value: 'domain' },
                  { label: '虛擬主機', value: 'vps' },
                  { label: '實體主機', value: 'host' },
                ]"
                class="w-100"
                :reduce="option => option.value"
                @input="searchTypeChange"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="6"
              sm="6"
              class="mb-md-0 mb-1"
            >
              <label>商品類別</label>
              <v-select
                v-model="searchProductType"
                :options="productTypeOptions[searchType]"
                :reduce="option => option.value"
                :disabled="!searchType"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                購物車列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="多選"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="onSubmitDelete(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/select-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>

                <div
                  v-if="isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="一鍵刪除"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitMutiDelete"
                >
                  <b-img
                    src="/dashboard/admin/images/table/delete-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitTableCancel"
                >
                  <b-img
                    src="/dashboard/admin/images/table/cancel.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <!-- hover-->
        <b-table
          ref="refDataListTable"
          :items="getCartListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          primary-key="id"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          select-mode="multi"
          selectable
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
          @row-selected="onRowSelected"
        >
          <!-- :sort-by.sync="sortBy" -->
          <template #head(selected)>
            <span
              class="text-primary cursor-pointer"
              @click.stop="!isSelectedAll ? selectAllRows() : clearSelected()"
            >
              {{ isSelectedAll ? '取消' : '全選' }}
            </span>
          </template>

          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 選取 -->
          <template #cell(selected)="data">
            <div class="table-col">
              <b-form-checkbox
                v-model="data.rowSelected"
                name="table-column"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(type)="data">
            <div class="table-col">

              <div
                class="ribbon-col"
                @click="onSubmitLink(data.item)"
              >
                <span
                  :style="{ backgroundColor: resolveProductType(data.item.product_type).color}"
                  class="text-nowrap"
                >
                  {{ resolveProductType(data.item.product_type).label }}
                </span>
              </div>
            </div>
          </template>

          <!-- 欄位: 商品 -->
          <template #cell(product)="data">
            <div
              class="cursor-pointer d-flex align-items-center justify-content-between"
            >
              <b-link
                class="font-weight-bold mb-0"
                @click="data.toggleDetails"
              >
                <div class="mb-0">
                  <div class="item-description server-name">
                    {{ ui.type[data.item.type.toLowerCase()] }}
                  </div>
                  <small class="text-muted">
                    <div v-if="data.item.product_type === 'domain'">
                      {{ data.item.info.prefix }}{{ data.item.product_info.suffix }} / {{ data.item.info.quantity }}年
                    </div>

                    <div v-if="data.item.product_type === 'vps'" />

                    <div v-if="data.item.product_type === 'host'" />
                  </small>
                </div>
              </b-link>
              <feather-icon
                :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="ml-25"
                @click="data.toggleDetails"
              />
            </div>
          </template>

          <!-- 欄位: 會員名稱 -->
          <template #cell(customer)="data">
            <div class="table-col">
              <b-media
                v-if="data.item.customer && data.item.customer_info"
                vertical-align="center"
              >
                <template #aside>
                  <b-link @click="linkCustomerInfo(data.item.customer_info)">
                    <b-avatar
                      size="32"
                      :src="data.item.customer_info.image"
                      :text="avatarText(data.item.customer_info.name)"
                      variant="light-primary"
                    />
                  </b-link>
                </template>
                <div class="d-block text-nowrap selection-group d-flex align-items-center">
                  <b-link class="font-weight-bold d-block text-nowrap show-text">
                    {{ data.item.customer_info.name }} {{ data.item.customer_info.family_name }}
                  </b-link>
                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="篩選"
                    class="selection-btn-icon-show"
                    @click="searchCol({
                      name: '會員SSID',
                      key: 'search_query',
                      value: data.item.customer,
                    })"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/filter2.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </span>

                  <b-link
                    v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                    v-b-tooltip.hover.focus.v-secondary
                    title="會員詳情"
                    class="selection-btn-icon-show"
                    @click="linkCustomerInfo(data.item.customer_info)"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/external-link.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </b-link>
                </div>
                <div
                  class="text-muted item-description-email"
                >
                  {{ data.item.customer_info.account }}
                </div>
              </b-media>

              <div
                v-else
                class="text-nowrap"
                @click="onSubmitServiceEdit(data.item)"
              >
                <b-avatar
                  size="32"
                  variant="light-primary"
                />
                <small class="text-muted ml-50">暫無資料</small>
              </div>
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 詳細 -->
          <template #row-details="data">
            <div class="table-toggle-detail border-top p-1">
              <b-card
                no-body
                class="m-0 border animate__animated animate__fadeIn"
              >
                <div>
                  <!-- <div class="text-right p-50">
                    <feather-icon
                      icon="XIcon"
                      size="20"
                      @click="data.toggleDetails"
                    />
                  </div> -->
                  <b-card-body>
                    <b-row>
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label-cols="4"
                          label="商品SSID"
                          class="m-0 selection-group"
                        >
                          <div class="mt-50">
                            {{ data.item.product ? data.item.product : '---' }}
                            <span
                              v-b-tooltip.hover.focus.v-secondary
                              title="篩選"
                              class="selection-btn-icon"
                              @click="searchCol({
                                name: '商品SSID',
                                key: 'search_query',
                                value: data.item.product,
                              })"
                            >
                              <b-img
                                src="/dashboard/admin/images/table/filter2.svg"
                                height="15"
                                width="15"
                                class="cursor-pointer"
                              />
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="data.item.product_type === 'domain'"
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label-cols="4"
                          label="網域後綴"
                          class="m-0 selection-group"
                        >
                          <div class="mt-50">
                            {{ data.item.product_info.suffix }}
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-for="(value, key) in data.item.info"
                        :key="key"
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label-cols="4"
                          :label="productDictionary[data.item.product_type][key] || key"
                          class="m-0 selection-group"
                        >
                          <div class="mt-50">
                            {{ value }}
                          </div>
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-card-body>
                </div>
              </b-card>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                class="actions-link-btn mr-25"
                @click="onSubmitLink(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="商品詳情"
                  src="/dashboard/admin/images/table/external-link.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="!isSelectState && checkAuthAbility('cart', 'API.Admin.Cart.Delete')"
                class="actions-link-btn mr-25"
                @click="onSubmitDelete(data.index)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/dashboard/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BAvatar, BCardBody, BMedia,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BCardHeader, BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'
import { useCartList, useCartSetting } from '../useCart'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BMedia,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BAvatar,
    BFormInput,
    BCardBody,
    BCardHeader,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.select).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.select)
    },
    tableColumnsSearchable() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.searchable).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.searchable)
      // return this.tableColumns.filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (搜尋)分類
    searchTypeChange(type) {
      if (!type) {
        this.searchProductType = null
        return
      }
      if (this.productTypeOptions[type].length > 0) {
        this.searchProductType = this.productTypeOptions[type][0].value
      } else {
        this.searchProductType = null
      }
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (前往)商品
    onSubmitLink(item) {
      if (item.product_type === 'domain') {
        const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Price')
        if (!auth) return
        this.$router.push({
          name: 'admin-domain-price-setting',
          params: {
            id: item.product_info.id,
          },
        })
      }
    },

    // (前往)會員詳情
    linkCustomerInfo(item) {
      const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-customer-view', params: { id: item.id } })
    },

    // (觸發選取)刪除列表物品
    onSubmitDelete(index) {
      this.isSelectState = true
      if (index !== null) this.selectRow(index)
    },

    // (選擇)列表物品
    onRowSelected(items) {
      if (this.isSelectState) {
        this.selectedTableData = items
      } else this.clearSelected()
    },

    // (觸發)列表取消
    submitTableCancel() {
      this.isSelectState = false
      this.clearSelected()
      this.selectedTableData = []
    },

    // (刪除)多選物品
    submitMutiDelete() {
      const items = this.selectedTableData.map(item => item.id)
      this.useSwalAlertWarning('刪除購物車', `你確定要永久刪除所選的 ${items.length} 項購物車商品嗎?`)
        .then(result => {
          if (result.value) {
            this.setCartDelete({
              cart_id: items,
            }).then(response => {
              this.refetchData()
              this.useSwalAlertCenter(response.data.success, `刪除${response.data.success ? '成功' : '失敗'}!`, response.data.message)
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const CART_ADMIN_STORE_MODULE_NAME = 'admin-cart'

    if (!store.hasModule(CART_ADMIN_STORE_MODULE_NAME)) store.registerModule(CART_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CART_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CART_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      searchType,
      searchProductType,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      isSelectState,
      isSelectedAll,
      selectedTableData,
      timeRange,

      setCartDelete,
      getCartListData,
    } = useCartList()

    const {
      ui,
      resolveProductType,
      productDictionary,
      productTypeOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useCartSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    const selectAllRows = () => {
      isSelectedAll.value = true
      refDataListTable.value.selectAllRows()
    }

    const clearSelected = () => {
      isSelectedAll.value = false
      refDataListTable.value.clearSelected()
    }

    const selectRow = index => {
      refDataListTable.value.selectRow(index)
    }

    const unselectRow = index => {
      refDataListTable.value.unselectRow(index)
    }

    return {
      ui,
      avatarText,
      productDictionary,
      productTypeOptions,
      resolveProductType,
      useAlertToast,
      dateConfig2,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      searchType,
      searchProductType,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      selectAllRows,
      clearSelected,
      selectRow,
      unselectRow,
      timeRange,
      isSelectState,
      isSelectedAll,
      selectedTableData,

      getCartListData,
      setCartDelete,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.ribbon-col {
  span {
    padding: 3px 8px;
    border-radius: 10px;
    border-style: outset;
    background-color: #3498dbe7;
    color: #fff;
    text-shadow: 0 1px 1px rgb(0, 0, 0);
    text-transform: uppercase;
    text-align: center;
    font-size: 12px
  }
}
.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}
</style>
